/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import Cookies from "js-cookie";
import { navigate } from "gatsby";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material";
import authorized from "../utils/auth";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/global.css";
import { QuickAccessbarProvider } from "./QuickAccessbar";

const theme = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: '1rem',
        },
      },
    },
  },
});

// Styled background component
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  filter: blur(100px);
  -webkit-filter: blur(100px);
  //backdrop-filter: blur(100px);
  // background: radial-gradient(
  //   circle,
  //   ${(props) => props.color} 0%,
  //   transparent 70%
  // );
  background: rgba(255, 255, 255, 0.9) url(/colorsplash.jpg);
  background-size: cover;
  transition: background-color 0.5s ease;
  z-index: -1; // Ensure this sits behind all other content
`;

// Component to use in your app
const BlurredBackground = ({ color = "rgba(255, 255, 255, 0.8)" }) => {
  return <Background color={color} />;
};

const Main = styled.main`
  display: flex;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 83vw;
`;
const FlexWrapper = styled.div`
  flex: 1;
  padding-left: 30px;
  background: white;
`;

const Layout = ({ children }) => {
  const apiToken = authorized();
  useEffect(() => {
    if (!apiToken) {
      navigate("/login");
    }
  }, []);
  // Also null when not loaded yet from localStorage
  if (!apiToken) {
    return <></>;
  }

  return (
    <>
      <BlurredBackground color={"gray"} />
      <Main>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QuickAccessbarProvider>
              <Sidebar />
              <Content id="maincontent">
                <Header />
                <FlexWrapper>{children}</FlexWrapper>
                <Footer version="6.0" />
              </Content>
              <ToastContainer />
            </QuickAccessbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
