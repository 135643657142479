import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { toast, ToastContent } from 'react-toastify';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import {
  MutationFunctionOptions,
  OperationVariables,
  DefaultContext,
  ApolloCache,
} from '@apollo/client';
import Button from './MVMButton';
import CloseButton from './CloseButton';
import { SubmitButtonState } from './MVMButton';

const DialogTitleWrapper = styled.div`
  h2 {
    display: flex;
    justify-content: space-between;
    min-width: 20rem;
  }
`;

const DialogContentWrapper = styled.div`
  min-height: 8rem;
  > div {
    height: 100%;
  }
`;



interface DialogProps {
  showDialog: (boolean) => void;
  title: string;
  content: JSX.Element;
  mutation: (
    options?:
      | MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>
      | undefined,
  ) => Promise<any>;
  validations?: any;
  mutationVariables: Record<string, any>;
  refetch: any;
  errors?: any[];
  setErrors?: any;
  submitButtonState?: SubmitButtonState;
  showSubmitButton?: any;
  submitButtonLabel?: string;
  successToast?: string;
}

const defaultProps = {
  errors: [],
  setErrors: () => null,
  validations: () => null,
  submitButtonState: SubmitButtonState.default,
  submitButtonLabel: 'Save',
  showSubmitButton: true,
  successToast: 'Saved',
};
/**
 * MVMDialog
 */
const MVMDialog: React.FC<DialogProps> = ({
  showDialog,
  title,
  content,
  validations,
  mutation,
  mutationVariables,
  refetch,
  errors,
  setErrors,
  submitButtonState,
  showSubmitButton,
  submitButtonLabel,
  successToast,
}: DialogProps) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validations) {
      validations();
    }

    if (!errors || (errors && errors.length === 0)) {
      const variables = mutationVariables;

      mutation({ variables })
        .then(() => {
          toast.success(successToast);
          refetch();
          showDialog(false);
        })
        .catch((e: Error) => {
          try {
            const errorsJson = JSON.parse(e.message.slice(15));
            const newErrors: any[] = [];
            Object.keys(errorsJson).forEach((key) => {
              const errorsArr = errorsJson[key];
              errorsArr.forEach((error) =>
                newErrors.push({ field: key, message: error }),
              );
            });
            if (setErrors) {
              setErrors(newErrors);
            }
          } catch (e) {
            toast.error(e as ToastContent);
          }
        });
    }
  };

  return (
    <Dialog open onClose={() => showDialog(false)}>
      <DialogTitleWrapper>
        <DialogTitle id="alert-dialog-title">
          {title}
          <CloseButton
            func={() => {
              showDialog(false);
            }}
          />
        </DialogTitle>
      </DialogTitleWrapper>
      <DialogContentWrapper>
        <DialogContent>{content}</DialogContent>
      </DialogContentWrapper>
      <DialogActions>
        {showSubmitButton && (
          <Button
            onClick={handleSubmit}
            label={submitButtonLabel}
            buttonState={submitButtonState}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

MVMDialog.defaultProps = defaultProps;
export default MVMDialog;
