exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-teams-tsx": () => import("./../../../src/pages/admin/teams.tsx" /* webpackChunkName: "component---src-pages-admin-teams-tsx" */),
  "component---src-pages-assets-tsx": () => import("./../../../src/pages/assets.tsx" /* webpackChunkName: "component---src-pages-assets-tsx" */),
  "component---src-pages-blueprint-tsx": () => import("./../../../src/pages/blueprint.tsx" /* webpackChunkName: "component---src-pages-blueprint-tsx" */),
  "component---src-pages-create-password-tsx": () => import("./../../../src/pages/create-password.tsx" /* webpackChunkName: "component---src-pages-create-password-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-media-files-tsx": () => import("./../../../src/pages/media-files.tsx" /* webpackChunkName: "component---src-pages-media-files-tsx" */),
  "component---src-pages-page-archive-tsx": () => import("./../../../src/pages/page_archive.tsx" /* webpackChunkName: "component---src-pages-page-archive-tsx" */),
  "component---src-pages-page-folders-tsx": () => import("./../../../src/pages/page_folders.tsx" /* webpackChunkName: "component---src-pages-page-folders-tsx" */),
  "component---src-pages-pages-tsx": () => import("./../../../src/pages/pages.tsx" /* webpackChunkName: "component---src-pages-pages-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-settings-country-groups-tsx": () => import("./../../../src/pages/settings/country-groups.tsx" /* webpackChunkName: "component---src-pages-settings-country-groups-tsx" */),
  "component---src-pages-settings-customer-groups-tsx": () => import("./../../../src/pages/settings/customer-groups.tsx" /* webpackChunkName: "component---src-pages-settings-customer-groups-tsx" */),
  "component---src-pages-settings-image-sizes-tsx": () => import("./../../../src/pages/settings/image-sizes.tsx" /* webpackChunkName: "component---src-pages-settings-image-sizes-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */),
  "component---src-pages-settings-page-contexts-tsx": () => import("./../../../src/pages/settings/page-contexts.tsx" /* webpackChunkName: "component---src-pages-settings-page-contexts-tsx" */),
  "component---src-pages-settings-publishing-targets-tsx": () => import("./../../../src/pages/settings/publishing-targets.tsx" /* webpackChunkName: "component---src-pages-settings-publishing-targets-tsx" */),
  "component---src-pages-settings-roles-tsx": () => import("./../../../src/pages/settings/roles.tsx" /* webpackChunkName: "component---src-pages-settings-roles-tsx" */),
  "component---src-pages-settings-tags-tsx": () => import("./../../../src/pages/settings/tags.tsx" /* webpackChunkName: "component---src-pages-settings-tags-tsx" */),
  "component---src-pages-settings-target-languages-tsx": () => import("./../../../src/pages/settings/target-languages.tsx" /* webpackChunkName: "component---src-pages-settings-target-languages-tsx" */),
  "component---src-pages-settings-team-settings-tsx": () => import("./../../../src/pages/settings/team-settings.tsx" /* webpackChunkName: "component---src-pages-settings-team-settings-tsx" */),
  "component---src-pages-settings-users-tsx": () => import("./../../../src/pages/settings/users.tsx" /* webpackChunkName: "component---src-pages-settings-users-tsx" */),
  "component---src-pages-team-signup-tsx": () => import("./../../../src/pages/team-signup.tsx" /* webpackChunkName: "component---src-pages-team-signup-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-translation-projects-index-tsx": () => import("./../../../src/pages/translation-projects/index.tsx" /* webpackChunkName: "component---src-pages-translation-projects-index-tsx" */),
  "component---src-pages-translation-projects-new-tsx": () => import("./../../../src/pages/translation-projects/new.tsx" /* webpackChunkName: "component---src-pages-translation-projects-new-tsx" */)
}

