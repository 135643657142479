import React, { useState } from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import Button from "./MVMButton";
import UserForm from "./UserForm";

const Wrapper = styled.div`
  form {
    margin-left: 20px;
  }
  position: sticky;
  top: 0;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1000; // Ensure it stays above other content
  display: flex;
  align-items: center;
  width: 100%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); // For Safari
  background-color: rgba(
    255,
    255,
    255,
    0.4
  ); // Semi-transparent background for the frosted glass effect
  box-sizing: border-box;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); // Optional: adds a subtle border
`;

const StyledInput = styled.input`
  flex: 1; // Added to take available space, leaving exact space for the icon
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  padding: 10px 40px 10px 20px; // Adjust padding to make room for the search icon on the right
  border: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }

  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const NoStyleButton = styled.button`
  border: 0;
  background: 0;
  outline: none;
  cursor: pointer;
`;

const SearchContainer = styled.div`
  position: relative;
  flex-grow: 1; // Ensure it takes available space
  display: flex;
  align-items: center;
`;

const IconContainer = styled(NoStyleButton)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Header: React.FC = () => {
  const logout = () => {
    // Cookies.remove('apiToken');
    localStorage.removeItem("apiToken");
    if (window !== undefined) {
      window.location.href = "/login";
    }
  };
  const [showDialog, setShowDialog] = useState(false);

  const jwt = localStorage.getItem("apiToken");
  const user = jwtDecode(jwt);
  const teamName = user.team_name;
  const userId = user.user_id;
  const { email } = user;

  return (
    <Wrapper>
      <form
        style={{ display: "flex", width: "100%" }}
        action="/search"
        method="get"
      >
        <SearchContainer>
          <StyledInput type="text" name="q" placeholder="Search..." required />
          <IconContainer>
            <SearchIcon />
          </IconContainer>
        </SearchContainer>
      </form>
      <Button
        label={teamName}
        onClick={() => {
          if (typeof window !== "undefined") {
            window.location.href = "/settings";
          }
        }}
      />
      <Button label={email} onClick={() => setShowDialog(true)} />
      <Button label="Logout" onClick={() => logout()} />
      {showDialog && (
        <UserForm
          id={userId}
          setShowDialog={setShowDialog}
          refetch={() => {}}
        />
      )}
    </Wrapper>
  );
};

export default Header;
