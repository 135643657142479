import { Link } from 'gatsby';
import React, {
  useMemo,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import StyledTooltip from './styled/StyledTooltip';

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
  height: 50px;
  width: max-content;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px); // For Safari
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px 20px;
  z-index: 10;
`;

const AccessButton = styled.div`
  & a {
    text-decoration: none;
    font-weight: bold;
    height: 35px;
    width: 35px;
    border-radius: 5px;
    color: ${(props) => props.color};
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); // For Safari
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

// Button Components
const PageAccessButton = ({ children, link = '', name }) => (
  <StyledTooltip title={name}>
    <AccessButton color="rgb(221, 145, 145)">
      <Link to={link}>{children}</Link>
    </AccessButton>
  </StyledTooltip>
);

const PagePreviewAccessButton = ({ children, link = '', name }) => (
  <StyledTooltip title={name}>
    <AccessButton color="rgb(194, 146, 221)">
      <Link to={link}>{children}</Link>
    </AccessButton>
  </StyledTooltip>
);

const TemplateAccessButton = ({ children, link = '', name }) => (
  <StyledTooltip title={name}>
    <AccessButton color="rgb(230, 173, 66)">
      <Link to={link}>{children}</Link>
    </AccessButton>
  </StyledTooltip>
);

// Access Type
type Access = {
  type: 'template' | 'page' | 'preview';
  name: string;
  link: string;
};

type State = {
  accessList: Access[];
};

type ContextValue = [State, (access: Access) => void];

const initialState: State = {
  accessList: [],
};

const QuickAccessbarContext = createContext<ContextValue | null>(null);

export const QuickAccessbarProvider = ({
  children,
}: React.PropsWithChildren<Record<string, never>>) => {
  const [state, setState] = useState<State>(initialState);

  const addAccess = (access: Access) => {
    let accessList = JSON.parse(
      localStorage.getItem('mvm_quick_access') ?? '[]',
    );
    accessList.filter(
      (acs: Access) =>
        acs.type !== access.type ||
        acs.name !== access.name ||
        acs.link !== access.link,
    );

    accessList = [
      access,
      ...accessList.filter(
        (acs: Access) =>
          acs.type !== access.type ||
          acs.name !== access.name ||
          acs.link !== access.link,
      ),
    ].slice(0, 5);

    localStorage.setItem('mvm_quick_access', JSON.stringify(accessList));
    setState({ accessList });
  };

  useEffect(() => {
    const accessList = JSON.parse(
      localStorage.getItem('mvm_quick_access') ?? '[]',
    ).slice(0, 5);
    localStorage.setItem('mvm_quick_access', JSON.stringify(accessList));
    setState({ accessList });
  }, []);

  const renderAccessButton = (access: Access) => {
    switch (access.type) {
      case 'page':
        return (
          <PageAccessButton link={access.link} name={access.name}>
            {access.name[0].toUpperCase()}
          </PageAccessButton>
        );
      case 'preview':
        return (
          <PagePreviewAccessButton link={access.link} name={access.name}>
            {access.name[0].toUpperCase()}
          </PagePreviewAccessButton>
        );
      case 'template':
        return (
          <TemplateAccessButton link={access.link} name={access.name}>
            {access.name[0].toUpperCase()}
          </TemplateAccessButton>
        );
      default:
        return null; // Or a default component
    }
  };
  const value = useMemo<ContextValue>(() => [state, addAccess], [state]);
  return (
    <QuickAccessbarContext.Provider value={value}>
      {children}
      {state.accessList.length > 0 && (
        <Wrapper>
          {state.accessList.map((access, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>{renderAccessButton(access)}</div>
          ))}
        </Wrapper>
      )}
    </QuickAccessbarContext.Provider>
  );
};

export const useQuickAccessbar = () => {
  const context = useContext(QuickAccessbarContext);

  if (!context) {
    throw new Error(
      'useQuickAccessbar must be used within a QuickAccessbarProvider',
    );
  }

  const [state, addAccess] = context;

  return { ...state, addAccess };
};
