import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

/**
 * A styled material-ui `Tooltip`
 *
 * Use `title` Prop to indicate information
 */
const StyledTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    fontSize: 12px;
  },
`

export default StyledTooltip;
